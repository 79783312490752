<template>
	<transition name="modal">
		<div v-if="isActive">
			<div class="modal" tabindex="-1" role="dialog" aria-labelledby="Modal" style="display: block"
				@click.stop="closeBackdrop">
				<div class="modal-dialog" :class="isLarge ? 'modal-lg' : 'modal-md'" role="document" @click.stop="">
					<div class="modal-content">

						<div class="modal-header  white" v-if="showHeader" :class="isError ? 'is-error' : 'bg-success'">
              <slot name="header">
                <h6 class="modal-title" v-if="heading" v-text="heading"></h6>
              </slot>
							<h6 class="modal-title" v-if="timer" v-text="timer.toString().toHHMMSS(true)"
								:class="{ 'is-warning': timer < 30 }"></h6>

							<button v-if="showClose" type="button" class="close btn" aria-label="Close" @click="emitClose">
								<span aria-hidden="true"><font-awesome-icon icon="times" /></span>
							</button>
						</div>
						<div class="modal-body">
							<slot />
						</div>
						<div class="modal-footer is-justify-space-between" v-if="!hasButtonsSlot && !noFooter">
							<button type="button" class="btn grey btn-outline-secondary"
								:class="{ 'is-loading': cancelLoading }" :disabled="submitLoading" @click="emitCancel"
								:style="cancelStyle"
								v-if="showCancel" v-text="cancelText"></button>
							<button type="button" class="btn btn-success" :class="{ 'is-loading': submitLoading }"
								:disabled="cancelLoading || submitDisabled" @click="emitOkay" v-if="showOkay"
								:style="submitStyle"
								v-text="submitText"></button>
						</div>
						<div class="modal-footer is-justify-space-between" v-else-if="!noFooter">
							<slot name="buttons"></slot>
						</div>
					</div>
				</div>
			</div>

			<div class="modal-backdrop show"></div>
		</div>
	</transition>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
library.add(faTimes)

export default {
	name: "b-modal",
	props: {
		"backdropClose": { default: true },
		"backdropCloseConfirm": { default: false },
		"backdropCloseConfirmText": { default: "Are you sure?" },
		showHeader: {
			type: Boolean,
			default: true
		},
		isError: {
			type: Boolean,
			default: false
		},
		showClose: {
			type: Boolean,
			default: true
		},
		'is-active': {
			type: Boolean,
			required: true
		},
		'is-large': {
			type: Boolean,
			default: false,
		},
		heading: {
			type: String
		},
		timer: {
			type: Number
		},
		showCancel: {
			type: Boolean,
			default: true
		},
		showOkay: {
			type: Boolean,
			default: true
		},
		cancelLoading: {
			type: Boolean,
			default: false
		},
		submitLoading: {
			type: Boolean,
			default: false
		},
		submitText: {
			type: String,
			default: 'Okay'
		},
		cancelText: {
			type: String,
			default: 'Cancel'
		},
		submitStyle: {
			type: String,
			default: ''
		},
		cancelStyle: {
			type: String,
			default: ''
		},
		submitDisabled: {
			type: Boolean,
			default: false
		},
		noFooter: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {}
	},
	components: {
		FontAwesomeIcon,
	},
	methods: {
		emitClose() {
			this.$emit('close');
		},
		emitCancel() {
			this.$emit('cancel');
		},
		emitOkay() {
			this.$emit('submit');
		},
		closeBackdrop() {
			if (!this.backdropClose) {
				return false;
			}
			if (this.backdropCloseConfirm && !confirm(this.backdropCloseConfirmText)) {
				return false;
			}
			this.emitClose();
			this.emitCancel();
		}
	},
	computed: {
		hasButtonsSlot() {
			return Boolean(this.$slots['buttons']);
		}
	}
}
</script>

<style lang="scss" scoped>
@import "/src/styles/variables";

.modal {
	overflow-x: hidden;
	overflow-y: auto;
}

button.close {
	cursor: pointer;
}

/* Transitions */
.modal-enter-active,
.modal-leave-active {
	transition: opacity .3s linear;
}

.modal-enter-active .modal,
.modal-leave-active .modal,
.modal-enter-active .modal-backdrop,
.modal-leave-active .modal-backdrop {
	transition: opacity .15s linear;
}

.modal-enter .modal,
.modal-leave-to .modal,
.modal-leave-active .modal,
.modal-enter .modal-backdrop,
.modal-leave-to .modal-backdrop,
.modal-leave-active .modal-backdrop {
	opacity: 0
}

.modal-enter-active .modal-dialog,
.modal-leave-active .modal-dialog {
	transition: transform .3s ease-out;
}

.modal-enter .modal-dialog,
.modal-leave-to .modal-dialog,
.modal-leave-active .modal-dialog {
	transform: translate(0, -25%);
}

.is-error{
  background-color: $dark-red;
}
</style>
